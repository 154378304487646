import { FC } from "react";
import "@milkdown/prose/view/style/prosemirror.css";
import "@milkdown/prose/tables/style/tables.css";
import "@milkdown/theme-nord/style.css";
import "~/../public/texteditor.css";
import { CustomMarkdown } from "./CustomMarkdown";
import { openReferenceType } from "~/doc-util";

interface CoverageReportEditorProps {
  markdown: string;
  openReference: openReferenceType;
  selectedText?: string;
}

export const CoverageReportEditor: FC<CoverageReportEditorProps> = ({
  markdown,
  selectedText = "",
  openReference,
}) => {
  // Trim whitespace from both ends and then remove the first backtick if it exists
  const cleanedMarkdown = markdown
    .trim()
    .replace(/^```markdown\n?/, "")
    .replace(/^`/, "");
  return (
    <div className="flex">
      <div className="flex-1 overflow-y-auto">
        <CustomMarkdown
          content={cleanedMarkdown}
          openReference={openReference}
          className="copilotKitMarkdown p-4"
          selectedText={selectedText}
        />
      </div>
    </div>
  );
};
