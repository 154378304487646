import { CopilotKit, useCopilotReadable } from "@copilotkit/react-core";
import { useLoaderData } from "react-router-dom";
import {
  fetchJobReport,
  findJobReportByEmail,
} from "~/api/query_fns/coverage-analysis";
import { DbDocumentType } from "~/api/query_fns/documents";
import { ReportTabs } from "~/components/ReportTabs";
import { EmailReportDisclaimer } from "~/components/EmailReportDisclaimer";
import { PDFViewerDialog } from "~/components/PDFViewerDialog";
import { useState } from "react";
import { useQuery } from "react-query";
import { useEffect } from "react";
import { useOpenReferenceDetails } from "../doc-util";
import { CustomChat } from "~/components/util-chat";
import {
  generateInitialMessage,
  getContentTypes,
  generateChatContext,
  getDocumentDetails,
} from "~/utils/util-report";
import { Message } from "../components/CustomMessages";
import { fetchDocumentTexts } from "~/utils";

interface LoaderParams {
  params: {
    jobId?: string;
  };
}

type FetchJobReportLoaderResponse = Awaited<ReturnType<typeof loader>>;

export const loader = async ({ params }: LoaderParams) => {
  const report = await fetchJobReport({ jobId: params.jobId || "" });
  await fetchDocumentTexts(report.documents);

  return report;
};

const GenReportPublic = () => {
  const [userId, setUserId] = useState("");
  const [locked, setLocked] = useState(true);
  const [isDisclaimerOpen, setIsDisclaimerOpen] = useState(true);
  const [content, setContent] = useState("AccessReportContent");
  const [queryExecuted, setQueryExecuted] = useState(false);
  const [email, setEmail] = useState("");
  const [warningText, setWarningText] = useState("");
  const [reportChatMessages, setReportChatMessages] = useState<Message[]>([]);

  const appendToReportChatMessages = (newMessages: Message[]) => {
    setReportChatMessages((prevMessages) => [...prevMessages, ...newMessages]);
  };

  const {
    id,
    from,
    reportName,
    reportKey,
    reportType,
    documents,
    coverageReport,
    followOnQuestions,
    processingStatus,
  } = useLoaderData() as FetchJobReportLoaderResponse;

  const unlock = (email: string) => {
    setWarningText("");
    setEmail(email.toLowerCase());
  };

  useEffect(() => {
    // Reset queryExecuted to false whenever the email changes
    setQueryExecuted(false);
  }, [email]); // Dependency array includes email to trigger effect when email changes

  useQuery(
    ["findJobReportByEmail", email, id],
    () => findJobReportByEmail({ email, report_email_job_id: id }),
    {
      onSuccess: (data) => {
        if (data?.unlock) {
          setUserId(data?.userId);
          setLocked(false);
          setContent("RenderSecurityContent");
        } else {
          setEmail("");
          setWarningText("* This email does not have access to the report.");
        }
        setQueryExecuted(true); // Set to true after successful fetch
      },
      enabled: !!email && !!id && !queryExecuted, // Only enable if email and id are set and query has not been executed
    }
  );

  const { factContent, policyContent, additionalContent, referenceContent } =
    getContentTypes(
      documents.map((doc) => ({
        ...doc.document,
        documentType: doc.document.documentType as DbDocumentType,
        claimNumber: doc.document.claimNumber || "",
        claimColor: doc.document.claimColor || "",
      }))
    );

  const {
    isDocViewerOpen,
    initialPage,
    document,
    setDocViewerState,
    openReference,
  } = useOpenReferenceDetails(documents);

  const context = generateChatContext(
    coverageReport,
    factContent,
    policyContent,
    additionalContent,
    referenceContent
  );

  useCopilotReadable({
    description: "all documentations",
    value: context,
  });

  const policyDetails = getDocumentDetails(documents, "policy");
  const factDetails = getDocumentDetails(documents, "fact");

  console.log("Policy File Name: ", policyDetails.fileName);
  console.log("Policy Presigned URL: ", policyDetails.presignedUrl);
  console.log("Fact File Name: ", factDetails.fileName);
  console.log("Fact Presigned URL: ", factDetails.presignedUrl);

  const initialMessage = generateInitialMessage(
    reportType,
    from,
    followOnQuestions
  );

  return (
    <CopilotKit url={`${import.meta.env.VITE_COPILOT_API_URL}/api/copilot`}>
      <CustomChat
        context={context}
        initialMessage={initialMessage}
        reportId={id}
        documents={documents}
        userId={userId}
        reportKey={reportKey}
        openReference={openReference}
        reportSource="email_report"
        appendToReportChatMessages={appendToReportChatMessages}
      >
        <div className="w-[48vw]">
          <PDFViewerDialog
            open={isDocViewerOpen}
            doc={document}
            initialPage={initialPage}
            setDocViewerState={setDocViewerState}
          />
          <div className="mx-auto w-full max-w-[90vw]">
            <ReportTabs
              reportName={reportName}
              fullCoverageReport={coverageReport}
              docs={documents}
              openReference={openReference}
              reportChatMessages={reportChatMessages}
            />
            <EmailReportDisclaimer
              setContent={setContent}
              content={content}
              locked={locked}
              isDisclaimerOpen={isDisclaimerOpen}
              setIsDisclaimerOpen={setIsDisclaimerOpen}
              onClose={() => console.log("Disclaimer closed")}
              unlock={unlock}
              warningText={warningText}
              processingStatus={processingStatus}
            />
          </div>
        </div>
      </CustomChat>
    </CopilotKit>
  );
};

export default GenReportPublic;
