import React, { useState, useEffect } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { Card, CardHeader, CardContent } from "~/components/ui/card";
import { Slider } from "~/components/ui/slider";
import { Label } from "~/components/ui/label";

interface InsuranceTowerChartProps {
  maxCoverage?: number;
}

const InsuranceTowerChart: React.FC<InsuranceTowerChartProps> = ({
  maxCoverage = 100,
}) => {
  const [data, setData] = useState(
    [
      {
        name: "ZAIC",
        fullName: "Zurich American Insurance Company - Primary",
        coverage: 32.5,
        start: 0,
        end: 32.5,
      },
      {
        name: "SRCSEIC",
        fullName:
          "Swiss Re Corporate Solutions Elite Insurance Corporation - Primary Excess",
        coverage: 25,
        start: 32.5,
        end: 57.5,
      },
      {
        name: "AIGSI",
        fullName: "AIG Specialty Insurance Company - 1st Excess",
        coverage: 20,
        start: 57.5,
        end: 77.5,
      },
      {
        name: "FFIC",
        fullName: "Fireman's Fund Indemnity Corporation - 2nd Excess",
        coverage: 10,
        start: 77.5,
        end: 87.5,
      },
      {
        name: "PESLIC",
        fullName:
          "The Princeton Excess and Surplus Lines Insurance Company - 3rd Excess",
        coverage: 12.5,
        start: 87.5,
        end: 100,
      },
    ].sort((a, b) => a.start - b.start)
  );

  const [lossAmount, setLossAmount] = useState(0);

  useEffect(() => {
    const newData = data.map((layer) => {
      if (lossAmount > layer.start) {
        const coverageUsed = Math.min(
          layer.coverage,
          Math.max(0, lossAmount - layer.start)
        );
        return { ...layer, coverageUsed };
      }
      return { ...layer, coverageUsed: 0 };
    });
    setData(newData);
  }, [lossAmount]);

  const handleSliderChange = (value: number[]) => {
    setLossAmount(Math.min(value[0], maxCoverage));
  };

  const renderCustomXAxisTick = ({ x, y, payload }: any) => {
    return (
      <g transform={`translate(${x},${y})`}>
        <text
          x={0}
          y={0}
          dy={16}
          textAnchor="end"
          fill="#666"
          transform="rotate(-35)"
          fontWeight="bold"
        >
          {payload.value}
        </text>
      </g>
    );
  };

  return (
    <Card className="mx-auto w-full max-w-3xl">
      <CardHeader>
        <h2 className="text-2xl font-bold">Insurance Tower Analysis</h2>
        <p className="text-sm text-muted-foreground">
          Max Coverage: ${maxCoverage} million
        </p>
      </CardHeader>
      <CardContent>
        <div className="mb-6 space-y-4">
          <div className="space-y-2">
            <Label htmlFor="loss-amount">
              Simulated Loss: ${lossAmount.toFixed(2)} million
            </Label>
            <Slider
              id="loss-amount"
              defaultValue={[0]}
              max={maxCoverage}
              step={0.1}
              onValueChange={handleSliderChange}
            />
          </div>
        </div>
        <ResponsiveContainer width="100%" height={500}>
          <BarChart
            data={data}
            margin={{ top: 20, right: 30, left: 20, bottom: 100 }}
          >
            <XAxis
              dataKey="name"
              interval={0}
              tick={renderCustomXAxisTick}
              height={100}
            />
            <YAxis
              label={{
                value: "Coverage ($ millions)",
                angle: -90,
                position: "insideLeft",
                style: { fontWeight: "bold" },
              }}
            />
            <Tooltip
              formatter={(value) => `$${(value as number).toFixed(2)} million`}
              labelFormatter={(label) => {
                const company = data.find((item) => item.name === label);
                return company ? company.fullName : label;
              }}
            />
            <Legend />
            <Bar
              dataKey="coverage"
              stackId="a"
              name="Total Coverage"
              fill="url(#totalCoverageGradient)"
            />
            <Bar
              dataKey="coverageUsed"
              stackId="a"
              name="Coverage Used"
              fill="url(#coverageUsedGradient)"
            />
            <defs>
              <linearGradient
                id="totalCoverageGradient"
                x1="0"
                y1="0"
                x2="1"
                y2="0"
              >
                <stop offset="0%" stopColor="#22c55e" stopOpacity={0.8} />
                <stop offset="100%" stopColor="#22c55e" stopOpacity={0.3} />
              </linearGradient>
              <linearGradient
                id="coverageUsedGradient"
                x1="0"
                y1="0"
                x2="1"
                y2="0"
              >
                <stop offset="0%" stopColor="#f97316" stopOpacity={0.8} />
                <stop offset="100%" stopColor="#f97316" stopOpacity={0.3} />
              </linearGradient>
            </defs>
          </BarChart>
        </ResponsiveContainer>
      </CardContent>
    </Card>
  );
};

export default InsuranceTowerChart;

/*

While the term "follow-form" generally implies a close adherence to the underlying policy, there are some variations in how excess policies can be structured to follow form. Here are some of the other types of follow-form excess policies:

1. Selective Follow-Form: This type of excess policy follows the majority of the terms and conditions of the underlying policy but may selectively deviate from certain provisions. These deviations could involve specific exclusions, deductibles, or limits that are modified or added in the excess layer.

2. Combination Follow-Form: This structure involves an excess policy that follows form to one or more underlying policies but might also include its own set of independent terms and conditions. This type of policy is often used when there are multiple underlying policies with different terms, and the excess layer seeks to provide a more uniform and consistent coverage structure.

3. Excess Follow Form with Drop-Down Provisions: While a standard follow-form excess policy does not typically drop down to cover losses excluded in the primary layer, it is possible to include specific drop-down provisions. These provisions would outline specific scenarios where the excess policy would respond as if it were primary coverage, even if the underlying policy does not provide coverage for the specific peril or circumstance.

4. Follow Form Subject to a Minimum Attachment Point: This type of excess policy would follow the form of the underlying policy, but only after a minimum amount of loss has been incurred, regardless of whether the underlying policy limits have been fully exhausted. This structure helps to ensure that the excess layer is triggered only for more significant losses.

It is important to note that the specific terms and conditions of any follow-form excess policy will be outlined in the policy wording. Always carefully review the policy language to understand the precise nature of how the excess layer follows form and any potential deviations or modifications from the underlying coverage.


export type TowerStructure = 'waterfall' | 'horizontal' | 'hybrid';
export type DefenseCosts = 'inside' | 'outside';

export interface Claim {
  id: string;
  amount: number;
  date: Date;
  peril: string;
  description: string;
}

export interface Sublimit {
  peril: string;
  amount: number;
}

export interface InsuranceLayer {
  id: string;
  name: string;
  fullName: string;
  coverage: number;
  start: number;
  end: number;
  year: number;
  attachmentPoint: number;
  dropDown: boolean;
  defenseCosts: DefenseCosts;
  coveredPerils: string[];
  sublimits: Sublimit[];
  aggregateLimit: number;
  usedAggregate: number;
  jurisdiction: string;
  regulatoryNotes: string;
  coverageUsed: number;
}

export interface InsuranceTower {
  structure: TowerStructure;
  selfInsuredRetention: number;
  maxCoverage: number;
  layers: InsuranceLayer[];
  claims: Claim[];
}


how would you adjust the following to handle all these scenearions:

Insurance tower analysis does not always follow a strict waterfall excess approach. While the waterfall concept is commonly used, there are different ways to structure an insurance tower. Here are some key points about insurance tower analysis:

## Common Tower Structures

### Waterfall Excess Approach

This is the traditional and most common structure:

- Coverage flows from the primary policy up through excess layers in order
- Each layer must be fully exhausted before the next layer responds
- Often referred to as "vertical exhaustion"

### Horizontal Exhaustion

- Requires exhaustion of all policies at a given layer before accessing higher layers
- Can apply across multiple years of coverage

### Hybrid Approaches

Some towers use a combination of vertical and horizontal exhaustion depending on the specific policies and claims involved.

## Factors Affecting Tower Analysis

### Policy Language

The specific wording of each policy determines how it interacts with other layers[1]. "Follow form" excess policies are becoming less common, with many excess policies now containing their own terms and conditions[1].

### Jurisdiction

Courts in different jurisdictions may interpret policy language and tower structures differently[3].

### Claim Type

Long-tail claims that span multiple policy periods can complicate tower analysis, especially when dealing with occurrence-based policies[3].

### Market Conditions

Current market trends are impacting tower structures:

- Capacity constraints are leading to smaller layers, typically $1-10 million rather than $25 million[2]
- Nuclear verdicts are pushing more claims into excess layers[2]

## Key Considerations

When analyzing an insurance tower:

- Carefully review all policy language, not just declarations pages[1]
- Look for potential gaps between layers[1]
- Consider how defense costs are treated (e.g., eroding limits vs. outside limits)[1]
- Understand any differences in coverage between primary and excess policies[1]

In conclusion, while the waterfall excess approach is common, insurance tower analysis can involve more complex structures depending on the specific policies, claims, and legal context involved. A thorough review of all relevant factors is essential for accurate tower analysis.

Citations:
[1] https://btlaw.com/en/insights/blogs/policyholder-protection/2015/five-tips-for-building-a-better-insurance-coverage-tower
[2] https://jencapgroup.com/insights/casualty/following-the-deal-path-to-build-an-excess-tower/
[3] https://coverage.memberclicks.net/assets/Annual_Meeting/2021AnnualMeeting/Papers/ACCC_2021AnnualConference_Papers_23Sept_1145_CanYouClimbtheExcessTower_BradleyBrownellGassmanFagelson_20210728.pdf
[4] https://www.eisneramper.com/insights/real-estate/waterfall-gp-catch-ups-0123/
[5] https://www.investopedia.com/terms/w/waterfallconcept.asp
[6] https://www.nuco.com/fcs/2018/01/29/coverage-layers/?slreturn=20240304194515
[7] https://www.linkedin.com/pulse/harnessing-power-waterfall-insurance-structured-approach-chris-gill-dwxae
[8] https://www.researchgate.net/publication/359538977_A_Comparative_Case_Study_of_Waterfall_and_Agile_Management


Additional:

import React, { useState, useEffect } from 'react';
import { BarChart, Bar, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { Card, CardHeader, CardContent } from '@/components/ui/card';
import { Slider } from '@/components/ui/slider';
import { Info } from 'lucide-react';

const initialData = [
  { name: 'Primary', coverage: 5, start: 0, end: 5 },
  { name: '1st Excess', coverage: 10, start: 5, end: 15 },
  { name: '2nd Excess', coverage: 25, start: 15, end: 40 },
  { name: '3rd Excess', coverage: 60, start: 40, end: 100 },
  { name: '4th Excess', coverage: 100, start: 100, end: 200 },
];

const InsuranceTowerChart = () => {
  const [data, setData] = useState(initialData);
  const [lossAmount, setLossAmount] = useState(0);
  const maxLoss = initialData.reduce((sum, layer) => sum + layer.coverage, 0);

  useEffect(() => {
    let remainingLoss = lossAmount;
    const newData = data.map(layer => {
      if (remainingLoss > 0) {
        const coverageUsed = Math.min(layer.coverage, remainingLoss);
        remainingLoss -= coverageUsed;
        return { 
          ...layer, 
          coverageUsed, 
          remainingCoverage: layer.coverage - coverageUsed,
        };
      }
      return { 
        ...layer, 
        coverageUsed: 0, 
        remainingCoverage: layer.coverage,
      };
    });
    setData(newData);
  }, [lossAmount]);

  const handleSliderChange = (value) => {
    setLossAmount(value[0]);
  };

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      const layerData = payload[0].payload;
      return (
        <div className="bg-background border border-border rounded p-2 shadow-md">
          <p className="font-semibold text-foreground">{label}</p>
          <p className="text-muted-foreground">Total Coverage: ${layerData.coverage}M</p>
          <p className="text-muted-foreground">Coverage Used: ${layerData.coverageUsed?.toFixed(2)}M</p>
          <p className="text-muted-foreground">Remaining: ${layerData.remainingCoverage?.toFixed(2)}M</p>
          <p className="text-muted-foreground">Range: ${layerData.start}M - ${layerData.end}M</p>
        </div>
      );
    }
    return null;
  };

  return (
    <Card className="w-full max-w-3xl mx-auto bg-background text-foreground">
      <CardHeader>
        <h2 className="text-2xl font-bold">Insurance Tower Analysis</h2>
      </CardHeader>
      <CardContent>
        <div className="mb-4">
          <label className="block text-sm font-medium mb-2">
            Simulated Loss: ${lossAmount} million
          </label>
          <Slider
            defaultValue={[0]}
            max={maxLoss}
            step={1}
            onValueChange={handleSliderChange}
          />
        </div>
        <div className="relative">
          <ResponsiveContainer width="100%" height={400}>
            <BarChart
              data={data}
              margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
            >
              <XAxis dataKey="name" stroke="hsl(var(--muted-foreground))" />
              <YAxis 
                stroke="hsl(var(--muted-foreground))"
                label={{ 
                  value: 'Coverage ($ millions)', 
                  angle: -90, 
                  position: 'insideLeft',
                  style: { fill: 'hsl(var(--muted-foreground))' }
                }} 
              />
              <Tooltip content={<CustomTooltip />} />
              <Legend />
              <Bar dataKey="coverageUsed" stackId="a" fill="hsl(var(--primary))" name="Coverage Used" animationDuration={300} />
              <Bar dataKey="remainingCoverage" stackId="a" fill="#9CA3AF" name="Remaining Coverage" animationDuration={300} />
            </BarChart>
          </ResponsiveContainer>
          <div className="absolute top-0 right-0 p-2">
            <Info className="text-muted-foreground" size={20} />
            <span className="sr-only">Hover over bars for more information</span>
          </div>
        </div>
        <p className="mt-4 text-sm text-muted-foreground">
          Adjust the slider to simulate different loss amounts. The chart updates to show how the loss fills up each layer of the insurance tower sequentially.
        </p>
      </CardContent>
    </Card>
  );
};

export default InsuranceTowerChart;
*/
